import { useState } from 'react';
import getAssetUrl from './utils/getAssetUrl';

function App() {
  const [count, setCount] = useState(0);

  return (
    <>
      <div>
        <img src={getAssetUrl('vite.svg')} className="logo" alt="Vite logo" />
        <img src={getAssetUrl('react.svg')} className="logo react" alt="React logo" />
      </div>

      <h1>Inspoenthusiast</h1>
      <div className="card">
        <button onClick={() => setCount(count => count + 1)}>count is {count}</button>
        <p></p>
      </div>
      <p className="read-the-docs">En Inspoenthusiast se vienen cositas</p>
    </>
  );
}

export default App;
